import React from 'react'
import InvestmentPlans from '../Components/LandingPageComponents/InvestmentPlans'
import MiningPlans from '../Components/LandingPageComponents/MinningPlans'

const AllPlans = () => {
  return (
    <div>
       
      <InvestmentPlans/>
      <MiningPlans/>
    </div>
  )
}

export default AllPlans
